<style scoped>

.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}


</style>

<template>
  <div>
  <v-flex class="pa-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
              <v-col class="" cols="6" sm="2" xs="12">
                  <v-select height="30" dense :items="symbolsList" v-model="instrument" hide-details outlined  label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
                  <v-text-field v-model="selectdate" label="Date" disabled dense hide-details outlined ></v-text-field>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
                <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
              <v-col class="" cols="6" sm="2" xs="12">
                <v-select v-model="strikeprice" :items="strickPriceList" dense multiple outlined hide-details
                       label="​Strike Price" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
             <v-col class="" cols="6" sm="2" xs="12">
               <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pb-0">
      <v-container fluid class="chartfullview1">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
            <v-card class="a-box pa-2">
                <div class="text-center font-weight-bold openSans mt-2">Multistrike OI - {{instrument}} {{expirydate}}</div>
              <v-chart class="chart" autoresize :options="multistrikeoi" />
            </v-card>
          </v-flex>
        </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import VChart, {} from "vue-echarts";
import "@/plugins/echart";
import { mapState } from "vuex"
export default {
  components: {
    VChart,
  },

  data() {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      instrument: "BANKNIFTY",
      expirydate: '25NOV21',
      strikeprice:'',
      multistrikeoi: {
        // title: {
        //   textStyle: { color: "lightgrey",fontSize: 20,},
        //   subtextStyle: { color: "lightgrey", fontSize: 18, fontWeight: 400, },
        //   text: "Amoga.tech",
        //   subtext: "Strangle Chart",
        //   left: "center",
        //   top: "center",
        // },
        tooltip: {trigger: "axis",},
        legend: { data: ["Combined", "VWAP"], bottom: "1%",},
        grid: { left: "3%", right: "4%", bottom: 60, containLabel: true,},
        toolbox: {
          feature: {
            //dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: true, title: 'Restore' },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: { type: "shadow",shadowColor: "#E6E5E4",},
          data: [ "10.1", "11.1", "12.1", "13.1", "14.1", "15.1", "16.3", "17.3", "18.2", "18.9", "19.5", "20.5", "21.7", "22.9",],
        },
        yAxis: {type: "value",},
        series: [
          { name: "Combined", type: "line", smooth: true, stack: "",
            data: [ 22.0, 13.2, 57.01, 3.4, 19.0, 93.0, 21.0, 15.9, 78.9, 12.0, 67.0, 33.8, 89.2, 13.8,],
          },
          { name: "VWAP", type: "line", stack: "", smooth: true,
            data: [ 12.0, 13.2, 67.01, 13.4, 19.0, 53.0, 29.0, 6.9, 81.9, 12.0, 67.0, 78.8, 19.2, 17.8,],
          },
        ],
      },
    };
  },
   methods:{
      getCurrentPrice (value) {
      if(value)
      return this.strickPriceList.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    },  
    async initialFunctions() {
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
      await this.$store.commit('symbols/setExpiryList', this.instrument);
      await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
      this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
    }
  },
  computed: {
      ...mapState('symbols',['symbolsList','expiryList','strickPriceList','currentExpiryDate']),
    },

  mounted() {
      var isDark = localStorage.getItem("dark_theme");
      var theme = (isDark == 'true')?'dark':'light';
      // this.option = Object.assign(this.global.chart.defaultTheme[theme],this.option);
       this.initialFunctions()
  },
};
</script>



